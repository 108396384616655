<template>
  <div class="partMeeting-container">
    <van-sticky>
      <head-bar type="primary" :leftArrow="true" left-text="" title="现场直播" @onClickLeft="onClickLeft" />
    </van-sticky>
    <div class="main">
      <div id="id_test_video" style="width:100%; height:auto;"></div>
    </div>
  </div>
</template>

<script>
import headBar from "@/components/headBar/headBar";
// import { Dialog, Toast } from 'vant'
export default {
  components: { headBar },
  data() {
    return {
      list: [
        // { label: '科技创新，引领数字未来', date: '08-31 13:30-17:00', link: '' },
        // { label: '科技创新，引领数字未来', date: '08-31 13:30-17:00', link: '' },
        // { label: '科技创新，引领数字未来', date: '08-31 13:30-17:00', link: '' },
        // { label: '科技创新，引领数字未来', date: '08-31 13:30-17:00', link: '' },
        // { label: '科技创新，引领数字未来', date: '08-31 13:30-17:00', link: '' }
      ]
    }
  },
  mounted() {
    // this.getLiveList()
    this.play()
  },
  methods: {
    onClickLeft() {
      this.$router.push("/index/" + this.$store.state.miceInfo.miceLink);
    },
    play() {
      const player = new TcPlayer('id_test_video', {
        "m3u8": "http://play-sh.quklive.com/live/1661831762109336.m3u8", //请替换成实际可用的播放地址
        "autoplay" : true,      //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
        "poster" : "",
        "width" :  '480',//视频的显示宽度，请尽量使用视频分辨率宽度
        "height": '320'//视频的显示高度，请尽量使用视频分辨率高度
      });
      console.log(player)
    }
  }
}
</script>

<style lang="less" scoped>
  .partMeeting-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: url('https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202208291150334691475.png') no-repeat;
    background-size: 100% 100%;
    .main {
      flex: 1;
      padding-bottom: 25px;
      overflow-y: auto;
     
    }
  }
</style>